import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout/Index"
import Socmed from "../components/commons/socmed/Index"
import { withTrans } from "../i18n/withTrans"

const Index = ({ data, location: { href }, t }) => {
  return (
    <Layout>
      <Container className="news-detail">
        <Row className="mini-news-container">
          <Col md={{ span: 8, offset: 2 }}>
            <label className="title">{data?.wpBerita?.title}</label>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="mini-full-width">
                <img
                  className="author-thumbnail"
                  src={data?.wpBerita?.lastEditedBy?.node?.avatar?.url}
                  alt=""
                />
                <span className="author-name">
                  {data?.wpBerita?.lastEditedBy?.node?.name}
                </span>
              </div>
              <div className="mini-full-width">
                <span className="primary-label">{data?.wpBerita?.date}</span>
              </div>
            </div>
            <img
              className="thumbnail"
              src={data?.wpBerita?.news?.thumbnail?.sourceUrl}
              alt=""
            ></img>
            <div
              dangerouslySetInnerHTML={{ __html: data?.wpBerita?.content }}
              className="content"
            />
            <span className="grey-label mb-2">{t("article.author")}</span>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img
                  className="author-thumbnail"
                  src={data?.wpBerita?.lastEditedBy?.node?.avatar?.url}
                  alt=""
                />
                <span className="author-name">
                  {data?.wpBerita?.lastEditedBy?.node?.name}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <span className="fw-bold me-3">{t("article.share")}</span>
                <Socmed
                  srcIcon="../../images/share_ic_fb.png"
                  link={`https://www.facebook.com/sharer/sharer.php?u=${href}`}
                />
                <Socmed
                  srcIcon="../../images/share_ic_tw.png"
                  link={`http://twitter.com/share?url=${href}`}
                />
                <Socmed
                  srcIcon="../../images/share_ic_link.png"
                  link={`https://api.whatsapp.com/send?text=${href}`}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpBerita(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "dddd, MMMM DD, YYYY | hh:mm")
      news {
        thumbnail {
          sourceUrl
        }
      }
      lastEditedBy {
        node {
          name
          avatar {
            url
          }
        }
      }
    }
  }
`

export default withTrans(Index)
